html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  min-height: 100vh;
}

.center-text {
  -webkit-appearance-text-align: justify;
  -moz-appearance-text-align-last: center;
}
@supports (-webkit-appearance: none) {
  .center-text {
    text-align: justify;
    text-align-last: center;
  }
}
_:-moz-tree-row(hover),
.center-text {
  text-align: justify;
  text-align-last: center;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .center-text {
    text-align: justify;
    text-align-last: center;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .center-text {
    text-align: justify;
    text-align-last: center;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (display: flow-root) {
    @media (min-width: 280px) {
      .center-text {
        text-align: justify;
        text-align-last: center;
      }
    }
  }
}



/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.creat-facility-toast {
  width: 30%;

  @media (max-width: 400px) {
    width: 100%;
    margin: 0%;
  }

  @media (min-width: 399px) and (max-width: 800px) {
    width: 60%;
    margin-left: 40%;
  }

  margin-left: 70%;
  //margin-right: 60%;
}

.carousel-arrow {
  background-color: #e6ebf1 !important;
  top: 62% !important;
}

@media screen and (max-width: 1024px) {
  .navbar-brand img {
    width: 20rem;
    height: 7rem;
  }
}

@media screen and (max-width: 992px) {
  .navbar-brand img {
    width: 16rem;
    height: 7rem;
  }
}